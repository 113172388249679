<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <router-link to="/admin/settings" class="btn btn-success">بازگشت</router-link>
    <b-card title="Social Media Setting" class="mt-3">
      <form @submit.prevent="itemEdit" id="edit-item">
        <div v-for="(item, index) in items.image" :key="index">
          <b-form-group :label="item.label" :label-for="item.key">
            <input :id="item.key" :name="item.key" type="file">
          </b-form-group>
        </div>
        <div class="row">
          <div class="col-md-4" v-for="(item, index) in items.text" :key="index">
            <b-form-group :label="item.label" :label-for="item.key">
              <b-form-input :id="item.key" :name="item.key" v-model="item.value"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div v-for="(item, index) in items.textarea" :key="index">
          <b-form-group :label="item.label" :label-for="item.key">
            <b-form-textarea :id="item.key" :name="item.key" v-model="item.value"></b-form-textarea>
          </b-form-group>
        </div>
        <button class="btn btn-success" type="submit" :disabled="disabled">Update</button>
        <input type="hidden" value="PATCH" name="_method">
      </form>
    </b-card>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        url: '/api/admin/settings',
        items: [],
        title: 'تنظیمات',
        disabled: false,
        show: false,
      }
    },
    methods: {
      loadSetting() {
        this.show = true
        this.$axios.get(this.$root.baseUrl + '/api/admin/settings/social')
          .then(response => {
            this.items = response.data.data
            this.show = false
          })
      },
      itemEdit() {
        this.disabled = true
        let form = document.getElementById('edit-item')
        let formData = new FormData(form)
        const price = document.getElementById('edit-price')
        if (price != null) {
          let org_price = this.price <= 999 ? this.price : this.price.replace(/\D/g, "");
          formData.append(`${price.title}`, org_price);
        }
        this.$axios.post(this.$root.baseUrl + this.url, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$root.$set(this.items.data.data, this.edit.index, response.data.data)
            this.$root.$emit('bv::hide::modal', 'edit-modal')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
    },
    created() {
      this.loadSetting()
      document.title = this.title
    }
  }
</script>